import React from "react"
import Topbar from "../components/organisms/topbar"
import CustomLayout from "../Layouts/Layout"

const IndexPage = () => {
  return (
    <div>
      <Topbar />
      <CustomLayout />
    </div>
  )
}

export default IndexPage
